import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-meta-page"
import {
  Container, 
  Section,
  Box,
  Flex,
  Subhead,
  SubheadSmall,SubheadSmaller,
  Text, 
  Space } from "../components/ui"
import { textLeft } from "../tailor.module.css"


function ContentSectionSimple(props) {
  return (
    <Box center>
      <Space size={3} />
      <Subhead>{props.heading}</Subhead>
      <Text className={textLeft} >{props.text}</Text>
    </Box>
  )
}

// RH slightly differs from FeatureSection (jsx)
function ContentSection( props ) {
  // console.log("FeatureSection:",props)
  // console.log("FeatureSection option:",props.option)
  return (
    <Box width="full">
        <Flex id="FlexInner" gap={0} variant="column" style= {{ alignItems: "flex-start" }} >
          <Box id="BoxInner" >
            <Subhead>
              {props.heading}
            </Subhead>
            {props.children.map((data, index) => (
              <>
                <SubheadSmall 
                  style={{ fontSize: "1.2em" }}>
                    {data.subheading}
                </SubheadSmall>
                <SubheadSmaller 
                  style={{ margin: "0 0 20px 0" }}>
                    {data.textBold}</SubheadSmaller>
                <Text key={index} as="p" >{data.textContent}</Text>
              </>
            ))}
          </Box>
        </Flex>
    </Box>
  )
}


const NewPage = ({ data }) => {

  // contains high-level info for react helmet
  const thisPage = data.allSitePage.edges[0].node.pageContext.content[0]

  // extracts content of interest
  const thisContent = thisPage.inhalt[0]
  console.log("meta: thisContent",thisContent)
  
  return (
    <Layout {...thisPage}>
      <Section>
        <Container width="normal">
          <Box center >
            <h1>{thisContent.purpose}</h1>
            <Text as="p" variant="body" className={textLeft} >{thisContent.intro}</Text>

            {thisContent.sections.map((feature, i) => (
                <ContentSection key={i} {...feature} />
              ))}
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq:$path } }) {
      edges {
        node {
          pageContext
        }
      }
    }

  }
`
export default NewPage